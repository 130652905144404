import React from "react";

interface Config {
  apiBaseUrl: string | undefined;

  // Whether to load real data or fake
  mode: "real" | "fake" | "fake-frontend";

  // Configuration related to the API server
  server: {
    // The server host or listening IP
    host: string;
    // The server listening port
    port: number;
  };

  // Configuration for bitcoind's JSON-RPC server
  bitcoinRpc: {
    // Server host IP or domain.
    server: string;
    // Username credentials.
    user: string;
    // Password credentials.
    password: string;
  };

  minerWindow: number;

  // Information about the softfork in question should be added here.
  // Things inside here will most likely be used and shown on the webpage.
  fork: {
    // The common name of this softfork.
    name: string;
    // The code name of this softfork.
    codename: string;
    // Information about this softfork, each array item is rendered as a paragraph.
    info: string[];
    // The BIP9 version bit as defined in the softfork's BIP.
    versionBit: number;
    // Threshold for the softfork to be locked in
    threshold: number;
    // Status of the softfork
    status: "defined" | "started" | "locked_in" | "active";
    // Block height the softfork gets activated.
    activationHeight: number;
    // Show a countdown timer until the softfork is activated.
    // Only available when the `status` is `locked_in`.
    showActivationCountdown: boolean;
    // Show celebration confetti on the site.
    showCelebrationConfetti: boolean;
  };

  // Configuration specifically for the frontend site
  frontend: {
    // How often to auto-refresh, in seconds. Set to null to disable
    autoRefreshInterval: number | null;
    // Twitter handle, this is for the Twitter link preview
    twitterHandle: string;
    // Celebratory video to display once lock-in is reached
    celebrate?: {
      type: string;
      url: string;
    };
    // Content related to the About page
    about?: {
      // Information about the softfork, it's allowed to use
      // React components here.
      // Use the Online Babel JSX Transpiler to create React components: https://babeljs.io/repl
      softfork?: {
        info?: React.ReactNode[];
      };
      // Information related to the current deployment method being
      // used for this softfork (i.e BIP9, Speedy Trial etc)
      method?: {
        title: React.ReactNode;
        info: React.ReactNode[];
      };
    };
    // Sponsors of this project
    sponsors?: {
      title: string;
      url: string;
      imageUri: string;
    }[];
  };
}

// MAINNET

const mainnetConfig: Config = {
  apiBaseUrl: "/api",

  mode: "real",

  server: {
    host: "127.0.0.1",
    port: 8100,
  },

  bitcoinRpc: {
    server: "http://127.0.0.1:12037",
    user: "x",
    password: "dN8nSsgSu4pphGUXRWz3b6BPoA4qSraS",
  },

  minerWindow: 2016,

  fork: {
    name: "ICANN Lockup",
    codename: "icannlockup",
    info: [],
    versionBit: 1,
    threshold: 1916,
    status: "defined",
    activationHeight: 1,
    showActivationCountdown: true,
    showCelebrationConfetti: true,
  },

  frontend: {
    autoRefreshInterval: 5,
    twitterHandle: "",
    // celebrate?: {
    //   type: "video";
    //   url: "path";
    // };
    about: {
      softfork: {
        info: [
          React.createElement(
            React.Fragment,
            null,
            "Currently, after ~4 years from mainnet genesis (block 210240), all unclaimed \
            reserved names will be allowed to go up for auction. This soft fork prevents \
            auctions for some of these names for another 4 years (till block 419328)."
          ),
          React.createElement(
            "div",
            {className: "info-links"},
            "For more info: ",
            React.createElement(
              "a",
              { href: "https://github.com/handshake-org/hsd/pull/819", target: "_blank" },
              "Initial PR"
            ),
            React.createElement(
              "a",
              { href: "https://github.com/handshake-org/hsd/pull/828", target: "_blank" },
              "10k PR"
            ),
            React.createElement(
              "a",
              { href: "https://github.com/handshake-org/hsd/pull/834", target: "_blank" },
              "Timeline PR"
            ),
          ),
        ],
      },
      method: {
        title: "Details",
        info: [
          React.createElement(
            React.Fragment,
            null,
            React.createElement(
              "ul",
              null,
              React.createElement(
                "li",
                null,
                "The reserved names are sorted by new Alexa rankings and the top 10k are reserved for another 4 years (~Feb 2028)."
              ),
              React.createElement(
                "li",
                null,
                "ICANN TLDs remain locked permanantly."
              ),
              React.createElement(
                "li",
                null,
                "While this soft fork only prevents auctions, a future hard fork will re-enable claiming."
              ),
              React.createElement(
                "li",
                null,
                "The rest of the 100k reserved names are not affected and can be opened for auction after the reserved period ends (~Feb 2024)."
              ),
              React.createElement(
                "li",
                null,
                "BIP-9 signalling by miners takes place from 10th August 2023 to 31th December 2023."
              ),
              React.createElement(
                "li",
                null,
                "95% of the blocks in a 2 week period (2016 blocks) must signal for the soft fork to activate."
              )
            )
          ),
        ],
      },
    },
    sponsors: [],
  },
};

const config = mainnetConfig;

export default config;
